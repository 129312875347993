import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import select2 from "select2/dist/js/select2.full.js"
select2($)
window.select2 = select2

import Swiper from 'swiper/bundle'
window.Swiper = Swiper

import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"
window.PhotoSwipe = PhotoSwipe
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default

require("slick-carousel")

/* import './turn.js'; */